function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

interface BrandInfo {
  brand: string
  version: string
}

interface UserAgentData {
  brands: BrandInfo[]
}

function loadVersionBrowser(): { name: string; version: string | null } {
  if ('userAgentData' in navigator) {
    const uaData = (navigator as any).userAgentData
    let browsername: string
    let browserversion: string | null = null
    let chromeVersion: string | null = null
    for (let i = 0; i < uaData.brands.length; i++) {
      const brand = uaData.brands[i].brand
      browserversion = uaData.brands[i].version
      if (brand.match(/opera|chrome|edge|safari|firefox|msie|trident/i) !== null) {
        if (brand.match(/chrome/i) !== null) {
          chromeVersion = browserversion
        } else {
          browsername = brand.substr(brand.indexOf(' ') + 1)
          return {
            name: browsername,
            version: browserversion
          }
        }
      }
    }
    if (chromeVersion !== null) {
      return {
        name: 'chrome',
        version: chromeVersion
      }
    }
  }

  const userAgent = navigator.userAgent
  const ua = userAgent
  let tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: tem[1] || '' }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/)
    if (tem != null) {
      return { name: 'Opera', version: tem[1] }
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1])
  }
  return {
    name: M[0],
    version: M[1]
  }
}

export { urlBase64ToUint8Array, loadVersionBrowser }
