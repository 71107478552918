import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import { createHead } from '@unhead/vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import en from './locales/en.json'
import it from './locales/it.json'
import ElementPlus from 'element-plus'

import App from './App.vue'
import router from './router'
import setupAxios from './boot/axios'
import * as Sentry from '@sentry/vue'

import './styles/element/index.scss' // Assure this is imported before Element Plus styles
import './styles/index.scss'
import 'element-plus/theme-chalk/display.css'
import 'animate.css'

let lang = navigator.language.split('-')[0]

if (!['it', 'en'].includes(lang)) {
  lang = 'it'
}
document.documentElement.setAttribute('lang', lang)

const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages: { en: en, it: it }
})

const head = createHead()

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
setupAxios(router, app)
app.use(router)
app.use(i18n)
app.use(head)
app.use(ElementPlus)
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://9a69a16b2fe203567d4d2fb6dfc911e2@o1103224.ingest.sentry.io/4505973911257088',
    integrations: [
      Sentry.browserTracingIntegration({
        router: router,
        enableInp: true,}),
      Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
      Sentry.browserProfilingIntegration(),
      Sentry.metrics.metricsAggregatorIntegration(),
    ],
    tracePropagationTargets: [/^https:\/\/spesa\.caresa\.it/],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    profilesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5
  })
}

app.mount('#app')
