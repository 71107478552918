import { urlBase64ToUint8Array, loadVersionBrowser } from '@/push/utils'

import { api } from '@/boot/axios'

function requestPOSTToServer(data: {
  browser: string
  p256dh: string
  auth: string
  registration_id: string
}) {
  api.post('wp/device/web-push/',
    {
      browser: data.browser,
      p256dh: data.p256dh,
      auth: data.auth,
      registration_id: data.registration_id,
      application_id: "wp_spesa"
    }
  )
}

export async function subscribeToPush() {
  const resp = await api.get('wp/key/')
  if ('serviceWorker' in navigator) {
    const browser = loadVersionBrowser()

    navigator.serviceWorker
      .register(import.meta.env.MODE === 'production' ? '/sw.js' : '/dist/sw.js?version=1.0.3', {
        type: import.meta.env.MODE === 'production' ? 'classic' : 'module'
      })
      .then((reg: ServiceWorkerRegistration) => {
        return reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(resp.data.key)
        })
      })
      .then((sub: PushSubscription) => {
        const endpointParts: string[] = sub.endpoint.split('/')
        const registration_id: string = endpointParts[endpointParts.length - 1]
        const data = {
          browser: browser.name.toUpperCase(),
          p256dh: btoa(
            String.fromCharCode.apply(null, Array.from(new Uint8Array(sub.getKey('p256dh')!)))
          ),
          auth: btoa(
            String.fromCharCode.apply(null, Array.from(new Uint8Array(sub.getKey('auth')!)))
          ),
          registration_id: registration_id
        }
        requestPOSTToServer(data)
      })
      .catch((err: any) => {
        console.log(':^(', err)
      })
  }
}
