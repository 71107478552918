import { AxiosError } from 'axios'
import { isAxiosError } from 'axios'

export function handleApiError(error: AxiosError | any) {
  if (isAxiosError(error)) {
    throw error?.response?.data
  } else {
    throw error
  }
}

export function formatDatetimeRange(start: string, end: string) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const options: Intl.DateTimeFormatOptions = {
    dateStyle: 'full',
    timeStyle: 'short'
  }
  // Controlla se start ed end sono nello stesso giorno
  const isSameDay = startDate.toDateString() === endDate.toDateString()

  if (isSameDay) {
    // Formattazione per lo stesso giorno
    const formattedStartDate = new Intl.DateTimeFormat('it-IT', {
      dateStyle: 'full',
      timeStyle: undefined
    }).format(startDate)
    const formattedStartTime = new Intl.DateTimeFormat('it-IT', {
      dateStyle: undefined,
      timeStyle: 'short'
    }).format(startDate)
    const formattedEndTime = new Intl.DateTimeFormat('it-IT', {
      dateStyle: undefined,
      timeStyle: 'short'
    }).format(endDate)

    return `${formattedStartDate} dalle ${formattedStartTime} alle ${formattedEndTime}`
  } else {
    // Formattazione per date diverse
    const formattedStartDate = new Intl.DateTimeFormat('it-IT', options).format(startDate)
    const formattedEndDate = new Intl.DateTimeFormat('it-IT', options).format(endDate)

    return `${formattedStartDate} - ${formattedEndDate}`
  }
}
