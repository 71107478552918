export const ORDER_STATUS_PENDING = 'In sospeso'
export const ORDER_STATUS_PAYMENT_DECLINED = 'Pagamento rifiutato'
export const ORDER_STATUS_AUTHORIZED = 'Pagamento autorizzato'
export const ORDER_STATUS_READY_NOT_PAID = 'Pronto ma non pagato'
export const ORDER_STATUS_READY_PAID = 'Pronto e pagato'
export const ORDER_STATUS_PAID = 'Pagato'
export const ORDER_STATUS_DELIVERED = 'Consegnato'
export const ORDER_STATUS_CANCELED = 'Annullato'
export const ORDER_STATUS_TO_BE_FULFILLED = 'Da evadere e pagato'
export const ORDER_STATUS_TO_BE_FULFILLED_NOT_PAID = 'Da evadere ma non pagato'

export const SATISPAY_PAYMENT_CODE = 'satispay'

export const UPON_COLLECTION_PAYMENT_CODE = 'upon_collection'
