import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { api } from '@/boot/axios'
import { handleApiError } from '@/stores/utils'

export interface ProfileInterface {
  first_name: string
  last_name: string
  phone_number: string
  full_name?: string
  email?: string
}

export interface UserStateInterface {
  hasProfile: boolean
  status: string
  profile: ProfileInterface
  permissions: Array<string>
  role: number | null
}

export interface ChangePasswordFormData {
  password: string
  password2: string
  old_password: string
}

export interface RegisterFormData {
  first_name: string
  last_name: string
  password1: string
  password2: string
  email: string
  phone_number: string
}

const initialState = (): UserStateInterface => ({
  status: '',
  hasProfile: false,
  profile: {
    first_name: '',
    last_name: '',
    phone_number: ''
  },
  permissions: [],
  role: null
})

export const useUserStore = defineStore('user', {
  state: initialState,
  getters: {
    isStaff(state): boolean {
      return state.role === 1
    },
    isCustomer(state): boolean {
      return state.role === 2
    }
  },
  actions: {
    async getUserPermissions() {
      try {
        const { data } = await api.get('user/account/permissions/')
        this.role = data.role
        this.permissions = data.permissions
        return data
      } catch (error) {
        this.status = 'error'
        this.role = null
        this.permissions = []
        throw error
      }
    },

    async getUserProfile() {
      try {
        const { data } = await api.get('user/account/profile/')
        this.profile = <ProfileInterface>data
        return this.profile
      } catch (error) {
        this.status = 'error'
        this.profile = initialState().profile
        throw error
      }
    },
    async updateUserProfile({ ...values }: { [key: string]: string }) {
      try {
        const { data } = await api.patch('user/account/profile/update/', values)
        this.profile = <ProfileInterface>data
        return this.profile
      } catch (error: AxiosError | any) {
        this.status = 'error'
        handleApiError(error)
      }
    },
    async changePassword(data: ChangePasswordFormData) {
      try {
        await api.put('user/account/change-password/', data)
        return true
      } catch (error: AxiosError | any) {
        this.status = 'error'
        handleApiError(error)
      }
    },
    async resetPassword(data: { email: string }) {
      try {
        await api.post('user/account/reset-password/', data)
        return true
      } catch (error: unknown) {
        this.status = 'error'
        if (error instanceof AxiosError) {
          handleApiError(error)
        }
      }
    },
    async resetPasswordConfirm(data: {
      uid: string
      token: string
      password: string
      password_confirm: string
    }) {
      try {
        await api.post('user/account/reset-password-confirm/', data)
        return true
      } catch (error: unknown) {
        this.status = 'error'
        if (error instanceof AxiosError) {
          handleApiError(error)
        }
      }
    },
    async register(data: RegisterFormData) {
      try {
        await api.post('register/', data)
        return true
      } catch (error: unknown) {
        this.status = 'error'
        if (error instanceof AxiosError) {
          handleApiError(error)
        }
      }
    },
    resetUserData() {
      // Set the state back to its initial value
      Object.assign(this, initialState())
    }
  }
})
