<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>
<style lang="scss">
@import '@/assets/main.scss';
</style>

<script setup>
import { onMounted } from 'vue'
import { subscribeToPush } from '@/pushNotifications'

onMounted(() => {
  subscribeToPush()
})
</script>
