import { defineStore } from 'pinia'
import type { ShippingMethod, PaymentMethod, ShoppingSlot } from '@/stores/types'

export interface CheckoutStateInterface {
  step: number
  lastPaymentMethod: PaymentMethod | null
  lastShoppingSlot: ShoppingSlot | null
  lastShippingMethod: ShippingMethod | null
  customerNote: string
}

export const useCheckoutStore = defineStore('checkout', {
  state: (): CheckoutStateInterface => {
    return {
      step: 1,
      lastPaymentMethod: null,
      lastShoppingSlot: null,
      lastShippingMethod: null,
      customerNote: ''
    }
  },
  persist: true
})
