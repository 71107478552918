import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import { api } from '@/boot/axios'
import { ElNotification } from 'element-plus'
import { useBasketStore } from '@/stores/basket.store'
import { useUserStore } from '@/stores/user.store'
import { getCookie } from '@/utils/cookies'

export interface AuthStateInterface {
  accessToken: string
  status: string
  hasLoadedOnce: boolean
}

interface ResponseData401 {
  detail: string
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStateInterface => ({
    accessToken: '',
    status: '',
    hasLoadedOnce: false
  }),
  getters: {
    isAuthenticated(state): boolean {
      return !!state.accessToken
    },
    getToken(state): string {
      return state.accessToken
    }
  },
  actions: {
    async loginUser({ email, password }: { email: string; password: string }) {
      try {
        this.status = 'loading'
        const response = await api.post('login/', { email, password }, { withCredentials: true })

        this.status = 'success'
        this.accessToken = response.data.access
        this.hasLoadedOnce = true
        const userStore = useUserStore()
        const basketStore = useBasketStore()
        await userStore.getUserPermissions()
        await basketStore.fetchBasket()
        await userStore.getUserProfile()
        return response
      } catch (error: unknown) {
        this.status = 'error'
        this.hasLoadedOnce = true
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            const data = error?.response?.data
            throw { username: [data ? (data as ResponseData401).detail : ''] }
          }
          ElNotification({
            message: 'Errore sconosciuto. Riprova più tardi.',
            type: 'error',
            duration: 2000
          })
          throw { username: ' ', password: ' ' }
        }
      }
    },
    logoutUser: async function () {
      try {
        await api.post('user/auth/logout/', {}, { withCredentials: true })
        this.accessToken = ''
        const basketStore = useBasketStore()
        const userStore = useUserStore()
        basketStore.resetBasket()
        await basketStore.fetchBasket()
        userStore.resetUserData()
      } catch (error: unknown) {
        this.accessToken = ''
        this.status = 'error'
        this.hasLoadedOnce = true
        throw error
      }
    },
    refreshToken: async function () {
      return new Promise((resolve, reject) => {
        api
          .post('user/auth/refresh-token/', {}, { withCredentials: true })
          .then((response) => {
            this.status = 'success'
            this.accessToken = response.data.access
            this.hasLoadedOnce = true
            resolve(response)
          })
          .catch((error: AxiosError) => {
            this.status = 'error'
            this.hasLoadedOnce = true
            this.accessToken = ''
            if (error.response?.status === 401) {
              const data = error?.response?.data
              reject({ username: [data ? (data as ResponseData401).detail : ''] })
            } else {
              reject({ username: ['Error'] })
            }
          })
      })
    },
    checkToken: function () {
      return !!getCookie('has-token')
    }
  }
})
